
import { defineComponent } from "vue";
import List from "@/components/notice/List.vue";
import ListItem from "@/components/notice/ListItem.vue";
import BaseLine from "@/components/common/BaseLine.vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";

export default defineComponent({
  name: "NoticeDetail",
  components: { List, ListItem, BaseLine, BaseHeader2 },
  data() {
    return {
      post: {} as {
        id: number;
        title: string;
        content: string;
        is_fixed: boolean;
        tstamp: string;
      },
    };
  },
  mounted() {
    const postId = this.$route.params.id.toString();
    this.$axios.get(`/board/notice/${postId}`).then((res) => {
      this.post = res.data;
    });
  },
});

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cbc6e2be"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader2 = _resolveComponent("BaseHeader2")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_List = _resolveComponent("List")!
  const _component_BaseLine = _resolveComponent("BaseLine")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseHeader2, null, {
      "slot-middle": _withCtx(() => [
        _createTextVNode("공지 사항")
      ]),
      _: 1
    }),
    _createVNode(_component_List, null, {
      default: _withCtx(() => [
        _createVNode(_component_ListItem, {
          class: "",
          fontWeightTitle: '700',
          path: '#',
          title: _ctx.post.title,
          tstamp: _ctx.post.tstamp
        }, null, 8, ["title", "tstamp"])
      ]),
      _: 1
    }),
    _createVNode(_component_BaseLine, { color: 'var(--grey1-color)' }, null, 8, ["color"]),
    _createElementVNode("div", {
      class: "content",
      innerHTML: _ctx.post.content
    }, null, 8, _hoisted_1)
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", null, [
    _createVNode(_component_router_link, { to: _ctx.path }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.tstamp), 1)
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}

import { defineComponent } from "vue";

const __default__ = defineComponent({
  name: "BaseLine",
  props: {
    width: {
      type: String,
      default: "1px",
    },
    color: {
      type: String,
      default: "var(--blue1-color)",
    },
    style: {
      type: String,
      default: "dashed",
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "43006a21": (_ctx.width),
  "41e96bbe": (_ctx.color),
  "42cd5a0c": (_ctx.style)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__
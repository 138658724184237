
import { defineComponent } from "vue";

const __default__ = defineComponent({
  props: {
    path: {
      type: String,
      default: "#",
    },
    title: {
      type: String,
      default: "",
    },
    fontWeightTitle: {
      type: String,
      default: "400",
    },
    tstamp: {
      type: String,
      default: "",
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0e0a6926": (_ctx.fontWeightTitle)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__